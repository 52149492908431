










import {Component, Prop, Vue} from "vue-property-decorator";
import {mapState} from "vuex";
import XDataTable from "../hoc/Table.vue";
import {date} from "@/assets/scripts/form-fields/field-rules";

@Component({
  components: {XDataTable},
  computed: {
    ...mapState({contract: "contract"}),
  },
})
class AnalysisTable extends Vue {
  [x: string]: any;

  @Prop({required: true})
  readonly consumptionList!: [];
  @Prop({required: true})
  readonly channelInfo!: [];

  public get preparedHeaders() {
    let headers = [];
    headers.push({text: "Дата", value: "date"},)
    let keys = this.channelInfo === undefined ? [] : this.channelInfo;
    for (let key in keys) {
      headers.push({
        text: this.channelInfo[key],
        value: key
      })
    }
    return headers;
  }

  public get preparedConsumptionList(): Array<string[]> {
    const transpose = matrix => matrix[0].map((col, i) => matrix.map(row => row[i]));
    const {consumptionList} = this;
    let parsedConsumptionList = JSON.parse(JSON.stringify(consumptionList))
    if (!parsedConsumptionList.length) {
      return [];
    }
    let data = [];
    let dates = [];
    // наполняю данными по каналам (строки - это каналы, столбцы - это даты)
    for (let index = 0; index < parsedConsumptionList.length; index++) {
      if (Object.values(parsedConsumptionList[index]) !== undefined) {
        data[index] = Object.values(parsedConsumptionList[index]);
        if (Object.keys(parsedConsumptionList[index]).length > 0)
          dates = Object.keys(parsedConsumptionList[index]);
      }
    }
    // некоторые каналы могут быть пустыми, поэтому необходимо проставить им значения
    // и привести массив к виду, когда его можно транспонировать
    for (let index = 0; index < data.length; index++) {
      if (data[index] < dates.length) {
        for (let j = data[index].length; j < dates.length; j++) {
          data[index][j] = "";
        }
      }
    }
    // транспонирую массив, чтобы он соответствовал структуре таблицы
    data = transpose(data);

    // подставляю дату на первую позицию в каждой строке массива
    let result = [];
    for (let i = 0; i < data.length; i++) {
      let record = [];
      record.push(dates[i]);
      if (data[i] !== undefined) {
        for (let j = 0; j < data[i].length; j++) {
          record.push(data[i][j]);
        }
      }
      result.push(record);
    }
    return result;
  }

}

export default AnalysisTable;
