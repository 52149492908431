






import { Component, Mixins, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import TurnoversTabContent from "@/components/for-turnovers-and-reports-view/TurnoversTabContent.vue";
import ReportsTabContent from "@/components/for-turnovers-and-reports-view/ReportsTabContent.vue";
import AnalysisTabContent from "@/components/for-turnovers-and-reports-view/AnalysisTabContent.vue";
import AskueTabContent from "@/components/for-turnovers-and-reports-view/AskueTabContent.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {
    Page,
    XTabs,
    TurnoversTabContent,
    ReportsTabContent,
    AnalysisTabContent,
    AskueTabContent
  },
})
class TurnoversAndReports extends Vue {
  titles = ["Обороты", "Отчеты", "Анализ потребления", "АСКУЭ"];
  components = [TurnoversTabContent, ReportsTabContent, AnalysisTabContent, AskueTabContent];

  mounted() {
  }
}

export default TurnoversAndReports;
