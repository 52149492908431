<script>
import {Line, mixins} from "vue-chartjs";

export default {
  name: "ChartAskue",
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "chartOptions"],
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
};
</script>
<style lang="scss" scoped>
#line-chart {
  width: 100% !important;
  max-height: 550px !important;
}
</style>
